import React from 'react';

function AlbumDisplayPlaceholder() {
    return (
      <div className="albumDisplay">
        <div className="imgContainer">
        </div>
        <div className="imgContainer">
        </div>
        <div className="imgContainer">
        </div>
        <div className="imgContainer">
        </div>
        <div className="imgContainer">
        </div>
        <div className="imgContainer">
        </div>
      </div>
    )
  
  }

export default AlbumDisplayPlaceholder;